import { useContext, useEffect, useRef, useState, lazy, Suspense } from "react";
import { Link } from "react-router-dom";
import classNames from "classnames";
import SafeHtml from "../../components/safe-html";
import AppLinkCard from "../../components/app-link-card.jsx";
import Icon from "../../components/icon.jsx";
import useScrollable from "../../hooks/useScrollable";
import UserContext from "../../context/user";
import LanguageContext from "../../context/language.jsx";
import FavouriteContext from "../../context/favourites";
import bannerLeonardo from "../../images/banner-leonardo.svg";
import bannerLeonardoIris from "../../images/leonardo-iris.jpg";
import LoadingError from "../loading-error.jsx";
import Error from "../../components/error.jsx";
import CommunityMiniCard from "../../components/communities/community-minicard.jsx";
import placeholders from "../../utils/placeholders";
import HubMiniCard from "../../components/hub-minicard";
import useMyCommunities from "../../hooks/queries/useMyCommunities";
import tealiumWording from "../../utils/tealiumWording.js";

const EventBookingWidget = lazy(() => import("../../components/event-booking-widget"));
const HighlightedContent = lazy(() => import("../../components/highligthed-content"));
const WidgetSurvey = lazy(() => import("../../components/widget-survey"));

const SidebarList = ({ items, errorMsg, children }) => {
  const [listRef, scrollListOffset] = useScrollable();
  return (
    <div
      className={classNames("sidebar__list-wrapper", {
        "sidebar__list-wrapper--overlay": scrollListOffset >= 1,
      })}
    >
      {items ? (
        items.length ? (
          <ul ref={listRef} className="sidebar__list">
            {children}
          </ul>
        ) : (
          <Error msg={errorMsg} />
        )
      ) : (
        <LoadingError />
      )}
    </div>
  );
};

const SideBar = ({ appsLinksLink, communitiesLink, hubsLink, sideContent }) => {
  const { userProfile, config } = useContext(UserContext);
  const { labels, cmsLang } = useContext(LanguageContext);
  const { getFavourite, getFavouriteHubs } = useContext(FavouriteContext);
  const [loading, setLoading] = useState(true);
  const [favoriteLinks, setFavoriteLinks] = useState(new Array(2).fill({}));
  const [favoriteHubs, setFavoriteHubs] = useState(placeholders(2, "id"));

  const [favoritesAccordion, setFavoriteAccordion] = useState({
    hubs: false,
    links: false,
    communities: false,
  });
  const loadedFavorites = useRef(false);

  const { data: favoriteCommunities } = useMyCommunities({
    query: { booleans: {} },
    pageSize: 50,
    placeholderData: { pages: [{ list: placeholders(2, "id"), init: true }] },
  });

  useEffect(() => {
    if (cmsLang && loadedFavorites.current !== cmsLang) {
      // console.log("loadFavourites: ", loadedFavorites.current, cmsLang);
      loadedFavorites.current = cmsLang;
      const loadFavourites = async () => {
        setLoading(true);
        try {
          const [{ value: favLinks }, { value: favHubs }] = await Promise.allSettled([
            getFavourite("links", cmsLang, 50),
            getFavouriteHubs(cmsLang, 50),
          ]);
          if (!favLinks || !favHubs) {
            loadedFavorites.current = false;
          }
          setFavoriteLinks(favLinks);
          setFavoriteHubs(favHubs);
        } catch (err) {
          console.error("Error loading favourites: ", err);
          loadedFavorites.current = false;
          setFavoriteLinks();
          setFavoriteHubs();
        }
        setLoading(false);
      };
      loadFavourites();
    }
  }, [getFavourite, getFavouriteHubs, cmsLang]);

  const highlights = sideContent?.items?.filter(
    (item) => item?.newsType?.externalReference === "hp-widget"
  );

  const surveys = sideContent?.items?.filter(
    (item) => item?.newsType?.externalReference === "survey" && !!item?.linkURL
  );

  const filteredFavoriteLinks = favoriteLinks?.filter(
    (link) => link?.subjectTaxonomy?.[0]?.externalReference !== "avoid-duplication"
  );

  const events = sideContent?.items?.filter(
    (item) => item?.newsType?.externalReference === "event" && !!item?.linkURL
  );

  return (
    <aside
      className={classNames(
        "sidebar",
        (userProfile === undefined || loading) && "loading-skeleton"
      )}
    >
      <div
        className={classNames(
          "home__welcome d-none d-tablet-flex",
          userProfile === undefined && "loading-skeleton"
        )}
      >
        <div className="home__welcome-intro loading-highlighted">
          {userProfile && (
            <>
              {labels?.HELLO}{" "}
              <em>
                <strong translate="no">
                  {userProfile.firstName} {userProfile.lastName}
                </strong>
              </em>
            </>
          )}
        </div>
        <SafeHtml
          className="home__welcome-content loading-highlighted"
          html={userProfile ? labels?.WELCOME_TO_INTRANET : ""}
        />
      </div>
      <div className="sidebar__welcome">
        <a
          href={window.LEONARDO_URL}
          target="_blank"
          className="banner-leonardo d-tablet-none"
          data-element-id="home_banner_leonardo"
          data-description="image"
        >
          <img src={bannerLeonardo} className="banner-leonardo__bg" alt="" />
          <div className="banner-leonardo__content">
            <div className="banner-leonardo__title">{labels?.LEONARDO}</div>
            <div className="banner-leonardo__text">
              <span>{labels?.LEONARDO_CAPTION}</span>
              <Icon name="chevron" />
            </div>
          </div>
          <img src={bannerLeonardoIris} className="banner-leonardo__iris" alt="" />
        </a>
      </div>
      {highlights?.length > 0 && (
        <Suspense>
          <div className={classNames("sidebar__section sidebar__section--highlight d-tablet-none")}>
            {highlights.map((item) => (
              <HighlightedContent key={item.id} content={item} />
            ))}
          </div>
        </Suspense>
      )}
      {((!config.hideEvents && events?.length > 0) ||
        (!config.hideSurveys && surveys?.length > 0)) && (
        <div className={classNames("sidebar__surveys d-tablet-none")}>
          {!config.hideEvents && events?.length > 0 && (
            <Suspense>
              {events?.map((event) => (
                <EventBookingWidget
                  small={true}
                  key={event.id}
                  event={{
                    id: event?.linkURL,
                    imageUrl: event?.coverImage?.cropUrlTemplate,
                    categories: event?.intro,
                    intro: event?.description,
                  }}
                />
              ))}
            </Suspense>
          )}
          {!config.hideSurveys && surveys?.length > 0 && (
            <Suspense>
              {surveys?.map((survey) => (
                <WidgetSurvey
                  small={true}
                  key={survey.id}
                  survey={{
                    id: survey?.linkURL,
                    imageUrl: survey?.coverImage?.cropUrlTemplate,
                    categories: survey?.intro,
                    intro: survey?.description,
                    linkLabel: survey?.linkLabel,
                  }}
                  tealiumId={`home_banner_survey_${survey.id}`}
                />
              ))}
            </Suspense>
          )}
        </div>
      )}
      <div
        className={classNames(
          "sidebar__section d-tablet-none",
          favoritesAccordion.hubs && "sidebar__section--open"
        )}
      >
        <div className="sidebar__my-hubs">
          <div className="sidebar__section-title loading-highlighted">
            <h2 className="title-h3">{labels?.MY_HUBS || "My Hubs"}</h2>
            <div className="sidebar__my-hubs-description">
              {labels?.MY_HUBS_DESCRIPTION ||
                "Discover all your local info, services and welfare initiatives."}
            </div>
            <button
              className="sidebar__see-more"
              onClick={() =>
                setFavoriteAccordion((current) => ({ ...current, hubs: !current.hubs }))
              }
            >
              <Icon name="chevron" />
            </button>
          </div>
          {!!favoriteHubs?.length && (
            <ul className="sidebar__list sidebar__list--my-hubs-wrapper">
              {favoriteHubs?.map(
                (hub, i) =>
                  (loading ? true : hub.target) && (
                    <li key={i}>
                      <HubMiniCard hub={hub} loading={loading} labels={labels} />
                    </li>
                  )
              )}
            </ul>
          )}
          <Link to={hubsLink} className="button tertiary small loading-highlighted">
            <span>{labels?.BROWSE_YOUR_HUBS || "Browse your hubs"}</span>
          </Link>
        </div>
      </div>
      <div
        className={classNames(
          "sidebar__section d-tablet-none",
          favoritesAccordion.links && "sidebar__section--open"
        )}
      >
        <div className="sidebar__section-title loading-highlighted">
          <h2 className="title-h3">{labels?.MY_LINKS}</h2>
          <button
            className="sidebar__see-more"
            onClick={() =>
              setFavoriteAccordion((current) => ({ ...current, links: !current.links }))
            }
          >
            <Icon name="chevron" />
          </button>
        </div>
        <SidebarList items={filteredFavoriteLinks} errorMsg={labels?.NO_FAVORITE_LINKS}>
          {filteredFavoriteLinks?.map((link, i) => (
            <li key={i}>
              <AppLinkCard
                item={link}
                large={true}
                tealiumId={`home_banner_${tealiumWording(link.name)}_myLink`}
                showCategory={false}
              />
            </li>
          ))}
        </SidebarList>
        <div className="sidebar__link-wrapper">
          <Link to={appsLinksLink} className="cta-link sidebar__list-add loading-highlighted">
            <span>{labels?.EDIT_YOUR_FAVORITE_LINKS}</span>
            <Icon name="arrow" />
          </Link>
        </div>
      </div>
      <div
        className={classNames(
          "sidebar__section d-tablet-none",
          favoritesAccordion.communities && "sidebar__section--open"
        )}
      >
        <div className="sidebar__section-title loading-highlighted">
          <h2 className="title-h3">{labels?.MY_COMMUNITIES || "My communities"}</h2>
          <button
            className="sidebar__see-more"
            onClick={() =>
              setFavoriteAccordion((current) => ({ ...current, communities: !current.communities }))
            }
          >
            <Icon name="chevron" />
          </button>
        </div>
        <SidebarList
          items={favoriteCommunities?.list}
          errorMsg={labels?.NO_FAVORITE_COMMUNITIES || "No favourite communities yet"}
        >
          {favoriteCommunities?.list?.map((community) => (
            <li key={community.id}>
              <CommunityMiniCard item={community} backLink={communitiesLink} />
            </li>
          ))}
        </SidebarList>
        <div className="sidebar__link-wrapper">
          <Link to={communitiesLink} className="cta-link sidebar__list-add loading-highlighted">
            <span>{labels?.SEE_ALL}</span>
            <Icon name="arrow" />
          </Link>
        </div>
      </div>
      <div className="sidebar__links">
        <div>
          <Link to={hubsLink} className="sidebar__link">
            <Icon name="desktop-computer" />
            <h2>
              <SafeHtml html={labels?.HOME_MY_HUBS || "My<br/>hubs"} />
            </h2>
          </Link>
          <Link to={appsLinksLink} className="sidebar__link">
            <Icon name="share-link" />
            <h2>
              <SafeHtml html={labels?.HOME_MY_LINKS || "My<br/>links"} />
            </h2>
          </Link>
          <Link to={communitiesLink} className="sidebar__link">
            <Icon name="community" />
            <h2>
              <SafeHtml html={labels?.HOME_MY_COMMUNITIES || "My<br/>communities"} />
            </h2>
          </Link>
        </div>
      </div>
    </aside>
  );
};

export default SideBar;
