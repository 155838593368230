import { useContext, useEffect, useRef } from "react";
import UserContext from "../context/user";
import LanguageContext from "../context/language";
import Cookies from "js-cookie";
import md5 from "md5";
import getPageCountry from "./getPageCountry";

const LOGGING = false;
const COOKIE_NAME = "one-essilor-luxottica";

const acceptedCookies = () => {
  if (Cookies.get(COOKIE_NAME)) {
    try {
      const cookie = JSON.parse(Cookies.get(COOKIE_NAME));
      return cookie?.accepted && cookie?.analytics;
    } catch (err) {
      Cookies.remove(COOKIE_NAME);
      return false;
    }
  }
  return false;
};

const useTealiumOnPageLoad = (
  {
    notLogged,
    pageType,
    pageSection1 = "",
    pageSection2 = "",
    internalSearch,
    searchQuery,
    searchResults,
    searchCategory,
  },
  isReady = true
) => {
  const pageLoaded = useRef(false);
  const { userProfile } = useContext(UserContext);
  const { cmsLang } = useContext(LanguageContext);

  useEffect(() => {
    /* console.log(
      "notLogged",
      notLogged,
      "acceptedCookies()",
      acceptedCookies(),
      "pageLoaded.current",
      pageLoaded.current,
      "isReady",
      isReady
    ); */
    if ((notLogged ? true : userProfile) && acceptedCookies() && !pageLoaded.current && isReady) {
      pageLoaded.current = true;

      const pageLanguage =
        cmsLang ||
        localStorage?.getItem("lang-interface") ||
        localStorage?.getItem("lang") ||
        navigator?.language ||
        navigator?.userLanguage ||
        "en-us";

      const tealiumOnPageLoadObj = {
        id: "VirtualPage-View",
        Page_Brand: "1LX",
        Page_Environment: window?.tealium_env || "", // QA or PROD
        Page_Language: pageLanguage.split("-")[0].toUpperCase(), // two-letter uppercase	ISO 639-1 Eg. 'EN"
        Page_Country: getPageCountry(), // country ISO 2 uppercase i.e. 'IT', 'US', 'FR' (cp4)
        Page_Type: pageType || "Static", // 'Home' (only homepage), 'Landing' (pages with lists of news/link), 'Search' (search pages), 'Static' (everything else)
        Page_Section1: pageSection1, // first level section i.e. 'Home', 'news-detail' (cg4)
        Page_Section2: pageSection2, // second level section (COMMUNITYNAME cg3 or CATEGORY or DETAILPAGETITLE cp6)
        User_Id: userProfile?.username ? md5(userProfile.username.toLowerCase()?.trim()) : "", // user id 'Z9eAeIHPA6O07O9EC6OOCLHP86OG6N1E6MGHCJ1PA6OG6OHOCMQOCJPD0' (uc8)
        User_Country: (userProfile?.country || "")?.toUpperCase(), // user country (uc4)
        User_LoginStatus: userProfile ? "Logged" : "Guest", // user status can be "Logged" or "Guest"
        "1LX_User_WorkLocation": userProfile?.city || "", // uc1
        "1LX_User_BusinessUnit": `${userProfile?.zbusTypeT}:${userProfile?.macFunT}` || "", // uc2
        "1LX_User_BusinessFuntion": userProfile?.zbeFuncT || "", // uc3
        "1LX_User_GroupIdap": userProfile?.geographicalArea || "", // uc6
        "1LX_User_BrandRetail": userProfile?.subArea || "", // uc7
      };
      LOGGING && console.log("OnLoadPage Tealium push");
      LOGGING && console.table(tealiumOnPageLoadObj);
      window.tealium_data2track.push(tealiumOnPageLoadObj);
    }
  }, [
    userProfile,
    notLogged,
    pageSection1,
    pageSection2,
    pageType,
    internalSearch,
    searchQuery,
    searchResults,
    searchCategory,
    isReady,
    cmsLang,
  ]);
};

export const tealiumOnEvent = ({ eventType, eventAction, eventLabel, eventScope, eventSource }) => {
  if (acceptedCookies()) {
    const tealiumOnEventObj = {
      id: { appTool: "EditorialApp", article: "EditorialPage", file: "EditorialFile" }[eventType],
      Event_Action: eventAction,
      Event_Label: eventLabel,
      ...(eventScope && { Event_Scope: eventScope }),
      ...(eventSource && { Event_Source: eventSource }),
    };
    LOGGING && console.log("OnEvent Tealium push");
    LOGGING && console.table(tealiumOnEventObj);
    window.tealium_data2track.push(tealiumOnEventObj);
  }
};

export const useTealiumOnSearchLoad = (
  {
    pageType,
    pageSection1 = "",
    pageSection2 = "",
    internalSearch,
    searchQuery,
    searchResults,
    searchCategory,
  },
  isReady = true
) => {
  const { cmsLang } = useContext(LanguageContext);

  useEffect(() => {
    if (acceptedCookies() && isReady) {
      const pageLanguage =
        cmsLang ||
        localStorage?.getItem("lang-interface") ||
        localStorage?.getItem("lang") ||
        navigator?.language ||
        navigator?.userLanguage ||
        "en-us";

      const tealiumOnSearchObj = {
        id: "VirtualPage-View",
        Page_Language: pageLanguage.split("-")[0].toUpperCase(), // two-letter uppercase	ISO 639-1 Eg. 'EN"
        Page_Country: getPageCountry(), // country ISO 2 uppercase i.e. 'IT', 'US', 'FR' (cp4)
        Page_Type: pageType || "Static", // 'Home' (only homepage), 'Landing' (pages with lists of news/link), 'Search' (search pages), 'Static' (everything else)
        Page_Section1: pageSection1, // first level section i.e. 'Home', 'news-detail' (cg4)
        Page_Section2: pageSection2, // second level section (COMMUNITYNAME cg3 or CATEGORY or DETAILPAGETITLE cp6)
        Events_SearchRun: internalSearch || "0", // user used internal search '1', no internal search used '0'
        Search_Keyword: searchQuery || "", // user input text query or product UPC for barcode
        Search_Type: "text", // can be "text", "suggested", "img" or "barcode"
        Search_View: "SERP", // describe the search engine result type of view (SERP, SERB, NO-SERP)
        Search_ResultItemsQnt: searchResults || "", // number of returned results, 0 if no results
        Search_FacetValues_String: searchCategory || "", // product category tab where the user lands in english, if any. e.g "category=products"
      };
      LOGGING && console.log("OnLoadSearchPage Tealium push");
      LOGGING && console.table(tealiumOnSearchObj);
      window.tealium_data2track.push(tealiumOnSearchObj);
    }
  }, [
    cmsLang,
    internalSearch,
    isReady,
    searchQuery,
    searchCategory,
    searchResults,
    pageSection1,
    pageSection2,
    pageType,
  ]);
};

export default useTealiumOnPageLoad;
