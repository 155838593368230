import React, {
  lazy,
  Suspense,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { Link, NavLink, useLocation, matchPath } from "react-router-dom";
import classNames from "classnames";
import Icon from "./icon.jsx";
import LanguageContext from "../context/language.jsx";
import UserContext from "../context/user.jsx";

const ShareWidget = lazy(() => import("./share-widget.jsx"));

const Navigation = React.forwardRef(
  ({ menu: originalMenu, menuOpen, closeMenu, selectedLanguage, updateLanguage, options }, ref) => {
    const { labels } = useContext(LanguageContext);
    const { logout } = useContext(UserContext);
    const [openIdx, setOpenIdx] = useState(-1);
    const toggleOpen = useCallback((e) => {
      const clickedTab = e.currentTarget.name;
      setOpenIdx((value) => (Number(clickedTab) === value ? -1 : Number(clickedTab)));
    }, []);

    const menu = useMemo(() => {
      return originalMenu?.map((item) => ({
        ...item,
        children: item?.pageTemplate === "linkpage" ? [] : item?.children,
      }));
    }, [originalMenu]);

    const listPages = useMemo(
      () => [
        { type: "news", segment: "news-search-page" },
        { type: "notes", segment: "organizational-notes-search-page" },
        { type: "press", segment: "press-release-search-page" },
        { type: "mynews", segment: "my-news-page" },
      ],
      []
    );

    const customChildren = useMemo(() => ({ "my-hubs": ["regional-hubs", "business-hubs"] }), []);

    const locationRef = useRef();
    const location = useLocation();
    const isActive = useCallback(
      (link) => {
        /* Exact match */
        const exactMatch = !!matchPath({ path: `${link}/*`, end: false }, location.pathname);
        /* Custom children match: make business/regional hubs link active when navigating a hub */
        const customChildrenMatch = customChildren?.[link.split("/")?.[2]]?.some(
          (customChild) => !!matchPath({ path: `/${customChild}/*`, end: false }, location.pathname)
        );
        /*  Partial match: make business/regional hubs link active when navigating a hub (old version /hubs/regional-hubs/...) */
        // const partialMatch = !!matchPath(
        //   { path: "/" + link.split("/")?.[2], end: false },
        //   location.pathname
        // );
        /* Partial's parent match: make "about us" active when navigating a hub (i.e., when about-us/... is active) */
        const customChildrenParentMatch = !!originalMenu
          ?.find((page) => page.link === link)
          ?.children?.find((subpage) =>
            customChildren?.[subpage.link.split("/")?.[2]]?.some(
              (customChild) =>
                !!matchPath({ path: `/${customChild}/*`, end: false }, location.pathname)
            )
          );
        /* listPageMatch: 
        location.pathname = /notes/98840 -> news/organizational-notes-search-page ACTIVE
        location.pathname = news/organizational-notes-search-page -> news/news-search-page INACTIVE
        */
        const matchingListPage = listPages?.find(({ type }) =>
          location.pathname.startsWith(`/${type}`)
        );
        const listPageMatch = matchingListPage?.segment
          ? !!matchPath({ path: `${link}/*`, end: false }, `/news/${matchingListPage.segment}`) &&
            !listPages?.map((page) => page.segment).includes(location?.pathname.split("/")[2])
          : false;
        return exactMatch || customChildrenMatch || listPageMatch || customChildrenParentMatch;
      },
      [location, listPages, originalMenu, customChildren]
    );

    useEffect(() => {
      if (location !== locationRef.current) {
        setOpenIdx(-1);
      }
      locationRef.current = location;
    }, [location]);

    return (
      <nav className={classNames("navigation", menuOpen && "navigation--menu-open")} ref={ref}>
        <ul className="navigation__list">
          {menu
            ? menu.map((item, i) => (
                <li
                  className={classNames("navigation__item", {
                    active: openIdx === i,
                  })}
                  key={i}
                >
                  {item.link && !item.children?.length ? (
                    <NavLink
                      to={item.link}
                      className={classNames("navigation__link", {
                        active: isActive(item.link),
                      })}
                      onClick={closeMenu}
                    >
                      <Icon name="arrow" />
                      <span>{item.label}</span>
                    </NavLink>
                  ) : (
                    <button
                      className={classNames("navigation__link navigation__link--children", {
                        open: openIdx === i,
                        active: isActive(item.link),
                      })}
                      name={i}
                      data-link={item.link}
                      onClick={toggleOpen}
                    >
                      <Icon name="arrow" />
                      <span>{item.label}</span>
                      {openIdx !== i && <Icon name="plus" />}
                    </button>
                  )}
                  {!!item.children?.length && (
                    <ul className="navigation__sublist">
                      {item.children.map((child, j) => (
                        <li className="navigation__item" key={j}>
                          <NavLink
                            to={child.link}
                            className={classNames("navigation__link", {
                              active: isActive(child.link),
                            })}
                            onClick={closeMenu}
                          >
                            {child.label}
                          </NavLink>
                        </li>
                      ))}
                    </ul>
                  )}
                </li>
              ))
            : null}
          {
            <li
              className={classNames("navigation__item navigation__item--profile", {
                open: openIdx === -2,
              })}
              key={menu?.length}
            >
              <button
                className={classNames("navigation__link navigation__link--children", {
                  open: openIdx === -2,
                })}
                name={-2}
                onClick={toggleOpen}
              >
                <Icon name="arrow" />
                <span>{labels?.PROFILE}</span>
                {openIdx !== -2 && <Icon name="plus" />}
              </button>
              <ul className="navigation__sublist">
                <li className="navigation__item">
                  <Link to="/profile" className="navigation__link" onClick={closeMenu}>
                    {labels?.BOOKMARKS}
                  </Link>
                </li>
                <li className="navigation__item">
                  <Link to="/profile/settings" className="navigation__link" onClick={closeMenu}>
                    {labels?.MY_LANGUAGE}
                  </Link>
                </li>
                <li className="navigation__item">
                  <Link to="/profile/info" className="navigation__link" onClick={closeMenu}>
                    {labels?.MY_INFO}
                  </Link>
                </li>
                <li className="navigation__item">
                  <Link
                    to="/contact-and-support"
                    className="navigation__highlighted navigation__highlighted--contact-and-support"
                    onClick={closeMenu}
                  >
                    {labels?.CONTACT_AND_SUPPORT}
                    <Icon name="info-tooltip" />
                  </Link>
                </li>
                <li className="navigation__item">
                  <Link to="/login" className="navigation__highlighted" onClick={logout}>
                    {labels?.LOGOUT}
                    <Icon name="arrow" />
                  </Link>
                </li>
              </ul>
            </li>
          }
        </ul>
        <div className="navigation__footer-wrapper">
          <div className="navigation__language-label">
            <Icon name="globe" />
            <div className="navigation__language-text">
              {labels?.LANGUAGE}: <span>{selectedLanguage?.label}</span>
            </div>
            <select
              className="native-select__select"
              value={selectedLanguage?.value}
              onChange={(e) => {
                updateLanguage(options?.find((opt) => opt.value === e.target.value));
              }}
              aria-label="native-select-language"
            >
              {options?.map((option) => (
                <option value={option.value} disabled={option.disabled} key={option.value}>
                  {option.label}
                </option>
              ))}
            </select>
          </div>
          <Suspense>
            <ShareWidget className="navigation__share-widget" />
          </Suspense>
        </div>
      </nav>
    );
  }
);

export default Navigation;
